import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import { Stack } from '@mui/material'

import { FilesDownloadSegment } from '@/components/ProductDetailsPage/FilesDownloadSegment'
import { FilesTable } from '@/components/ProductDetailsPage/FilesTable'
import { SECTIONS_MAP } from '@/constants'
import { SectionLayout } from '@/layouts/SectionLayout'
import { useProduct } from '@/providers/p/ProductProvider'
import type { DocumentProps, DownloadableFile, FilesDownloadSegmentProps } from '@/types'
import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useGetDefaultFilesLanguage } from '@/utils/frontend/useGetDefaultFilesLanguage'
import { useSelectedFiles } from '@/utils/frontend/useSelectedFiles'
import { useT } from '@/utils/frontend/useT'
import { isNotEmpty } from '@/utils/plain/isEmpty'

import { DeclarationOfPerformance } from './DeclarationOfPerformance'
import SafetyDataSheets from './SafetyDataSheets'

const { id } = SECTIONS_MAP.documents
const dataCy = 'DocumentsSection'

const DocumentsSectionTable = () => {
  const { product } = useProduct()
  const { documents } = product

  const { t } = useTranslation('dam-assets')

  const { trackDownload } = useProductDetailAnalyticsTracking()

  const { defaultFilesLanguage, filesLanguages } = useGetDefaultFilesLanguage(documents)
  const [documentsLanguage, setDocumentsLanguage] = useState(defaultFilesLanguage)
  const documentsByCategories = documents[documentsLanguage]

  const { selectedIds, isAllSelected, onSelection, onSelectAll, linkToDownload, allDocuments } =
    useSelectedFiles(documentsByCategories)

  const onDownloadClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const files = Object.values(documentsByCategories)
      .flat()
      .filter((file) => selectedIds.includes(file.id))
    trackDownload({
      action: e.currentTarget.innerText,
      files,
      language: documentsLanguage,
      target: linkToDownload ?? '',
    })
  }

  const filesDownloadSegmentProps: FilesDownloadSegmentProps<DocumentProps> = {
    isAllSelected,
    selectedIds,
    onSelectAll,
    files: documentsByCategories,
    filesLanguage: documentsLanguage,
    filesLanguages,
    setFilesLanguage: setDocumentsLanguage,
    linkToDownload,
    onDownloadClick,
    dataCy,
    totalFilesCount: allDocuments.length,
  }

  const onDownloadActionClick = <T extends DownloadableFile = DownloadableFile>({
    buttonLabel,
    file,
    target,
  }: {
    file: T
    buttonLabel: string
    target: string
  }) => {
    trackDownload({
      action: buttonLabel,
      files: [file],
      language: documentsLanguage,
      target,
    })
  }

  return (
    <Stack spacing={4}>
      <FilesDownloadSegment {...filesDownloadSegmentProps} />

      <Stack spacing={4}>
        {Object.entries(documentsByCategories)?.map(([category, categoryDocuments]) => {
          const title = t(`assetCategories.${category}`) as string

          return (
            <FilesTable
              dataCy={`${dataCy}-FilesTable`}
              key={category}
              title={title}
              files={categoryDocuments}
              onDownloadActionClick={onDownloadActionClick}
              hasHeaderRow
              selectedIds={selectedIds}
              onSelection={onSelection}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

const DocumentsSection = () => {
  const { product } = useProduct()
  const { documents, dopKey, sdsLinkout } = product

  const { t } = useT({ keyPrefix: 'product' })

  return (
    <SectionLayout title={t('documents-section-title')} id={id} dataCy={dataCy}>
      <Stack spacing={4}>
        {isNotEmpty(documents) && <DocumentsSectionTable />}
        {isNotEmpty(dopKey) && <DeclarationOfPerformance dopKey={dopKey} />}
        {isNotEmpty(sdsLinkout) && <SafetyDataSheets sdsLinkout={sdsLinkout} />}
      </Stack>
    </SectionLayout>
  )
}

export default DocumentsSection
