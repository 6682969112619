import { Box, Button, Checkbox, Grid, MenuItem, Stack, TextField } from '@mui/material'

import type { FilesDownloadSegmentFn } from '@/types'
import { useT } from '@/utils/frontend/useT'

export const FilesDownloadSegment: FilesDownloadSegmentFn = (props) => {
  const {
    filesLanguage,
    setFilesLanguage,
    filesLanguages,
    linkToDownload,
    onDownloadClick,
    selectedIds,
    isAllSelected,
    onSelectAll,
    dataCy,
    totalFilesCount,
  } = props

  const { t } = useT()

  const totalSelectedFilesCount = selectedIds?.length || 0

  const hasNoSelection = totalSelectedFilesCount === 0

  const onLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilesLanguage(event.target.value)
  }

  const downloadButtonLabel = (() => {
    if (isAllSelected) return `${t('product.files.actions.download-all')} (${totalFilesCount})`
    if (hasNoSelection) return t('product.files.actions.download')
    return `${t('product.files.actions.download')} (${totalSelectedFilesCount})`
  })()

  const hasLink = linkToDownload != null

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Stack direction="row" spacing={2} alignItems="center">
            <Checkbox
              checked={isAllSelected}
              onClick={onSelectAll}
              color="secondary"
              inputProps={{
                // @ts-ignore
                'data-cy': `${dataCy}-checkbox`,
                'aria-label': t('product.files.actions.select-all'),
              }}
            />
            <Button
              disabled={hasNoSelection}
              data-cy={`${dataCy}-Button`}
              onClick={onDownloadClick}
              {...(hasLink && {
                target: '_blank',
                href: linkToDownload,
              })}
            >
              {downloadButtonLabel}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label id="select-language-label" style={{ display: 'none' }}>
            {t('common.footer.select-language')}
          </label>
          <TextField
            select
            value={filesLanguage}
            onChange={onLanguageChange}
            data-cy={`${dataCy}-filesLanguage-Select`}
            inputProps={{
              'aria-labelledby': 'select-language-label',
              'aria-label': t('common.footer.select-language'),
            }}
          >
            {filesLanguages?.map((language) => (
              <MenuItem
                key={language}
                value={language}
                data-cy={`${dataCy}-filesLanguage-MenuItem-${language}`}
              >
                {language?.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  )
}
