import { Button, Stack, Typography } from '@mui/material'

import { ReadingGrid } from '@/components/ReadingGrid'
import { DOP_URL } from '@/constants'
import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useT } from '@/utils/frontend/useT'

import { BulletList } from '../../BulletList'

export type DeclarationOfPerformanceProps = {
  dopKey: string
}

const dataCy = 'DeclarationOfPerformance'

export const DeclarationOfPerformance = (props: DeclarationOfPerformanceProps) => {
  const { dopKey } = props
  const { t } = useT({ keyPrefix: 'product.documents-section.DoP-section' })
  const { trackClickEvent } = useProductDetailAnalyticsTracking()

  const items = [t('number', { dopKey }), t('description')]

  const dopHref = `${DOP_URL}?dopkey=${dopKey}`

  return (
    <Stack spacing={2}>
      <Typography variant="h6" component="h2" data-cy={`${dataCy}-title`}>
        {t('title')}
      </Typography>
      <ReadingGrid dataCy={dataCy}>
        <BulletList items={items} dataCy={`${dataCy}-list`} />
      </ReadingGrid>

      <Button
        href={dopHref}
        onClick={() => trackClickEvent(t('action'), dopHref, 'external_cta_click')}
        target="_blank"
        sx={{
          width: {
            lg: 'fit-content',
          },
        }}
        data-cy={`${dataCy}-action`}
      >
        {t('action')}
      </Button>
    </Stack>
  )
}
